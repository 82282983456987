








import Vue from "vue";
import Component from "vue-class-component";
import {
  STRIPE_PUBLIC_KEY,
  PRICING_TABLE_ID
} from "@sportango/site-configuration/out/src/payments/config";

@Component({
  name: "subscription"
})
export default class Subscription extends Vue {
  get publicKey(): string {
    return STRIPE_PUBLIC_KEY;
  }

  get pricingTableId(): string {
    return PRICING_TABLE_ID;
  }
}
